import React, { useCallback, useEffect, useState } from "react"
import qs from "query-string"

import OrderDone from "../../components/checkout/done"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import { trackMap, trackPurchase } from "../../services/analytics"
import Medusa from "../../services/api"
import { useProducts } from "medusa-react"

const mapboxToken = process.env.GATSBY_MAPBOX_TOKEN

const CheckoutDone = ({ location }) => {
  const [order, setOrder] = useState()
  const [splitOrderCart, setSplitOrderCart] = useState(false)
  const [fetchingOrder, setFetchingOrder] = useState(true)
  const [mapLocation, setMapLocation] = useState()

  const { products, isLoading } = useProducts(
    {
      id: order?.items?.map((i) => i?.variant?.product_id),
      expand: "categories,type",
    },
    {
      enabled: Boolean(order?.items?.length),
      keepPreviousData: true,
    }
  )

  const handleLocation = useCallback(
    (data) => {
      const location = data.features[0]
      const willAppear = location.relevance > 0.6

      trackMap({
        order: order,
        location: location,
        accuracy: location.relevance,
        didAppear: willAppear,
      })

      if (willAppear) {
        return location
      }

      return null
    },
    [order]
  )

  useEffect(() => {
    const initializeOrders = async () => {
      const { o } = qs.parse(location.search)
      const { splitOrderCart } = qs.parse(location.search)

      if (splitOrderCart) {
        const originalCart = await Medusa.cart.retrieve(splitOrderCart).then(({ data }) => data.cart)

        originalCart && setSplitOrderCart(originalCart)
      }

      if (o) {
        Medusa.orders
          .retrieve(o)
          .then(({ data }) => {
            setOrder(data.order)
            setFetchingOrder(false)

            const checkoutContainer = document.getElementById(
              "klarna-container"
            )
            if (checkoutContainer) {
              const scriptsTags = checkoutContainer.getElementsByTagName(
                "script"
              )
              // This is necessary otherwise the scripts tags are not going to be evaluated
              for (var i = 0; i < scriptsTags.length; i++) {
                const parentNode = scriptsTags[i].parentNode
                const newScriptTag = document.createElement("script")
                newScriptTag.type = "text/javascript"
                newScriptTag.text = scriptsTags[i].text
                parentNode.removeChild(scriptsTags[i])
                parentNode.appendChild(newScriptTag)
              }
            }
          })
          .catch((err) => {
            console.log(err)
            setOrder({
              tooOld: true,
            })
            setFetchingOrder(false)
          })
      }
    }
    initializeOrders()
  }, [location.search])

  useEffect(() => {
    if (!order || !products?.length || !order?.items?.length) return
    const tokens = JSON.parse(localStorage.getItem("ots")) || []

    if (!tokens.includes(order.id)) {
      tokens.push(order.id)
      trackPurchase({
        ...order,
        items: order?.items?.map((item) => {
          const product = products.find(
            (p) => p.id === item.variant.product_id
          )
          const primaryCategoryName = product?.categories?.find(
            (c) => c.id === product?.primary_category_id
          )?.name
          return {
            ...item,
            category: primaryCategoryName || "",
          }
        }),
      })
    }
    localStorage.setItem("ots", JSON.stringify(tokens))
  }, [order, products])

  useEffect(() => {
    if (!order) return

    const load = async () => {
      const {
        city,
        address_1,
        postal_code,
        province,
        country_code,
      } = order?.shipping_address

      const encodedLocation = encodeURIComponent(
        `${address_1},${postal_code},${city},${province}`
      )

      const fetchUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedLocation}.json?country=${country_code}&access_token=${mapboxToken}`

      fetch(fetchUrl)
        .then((res) => res.json())
        .then((data) => {
          const location = handleLocation(data)
          setMapLocation(location)
        })
        .catch(() => setMapLocation(null))
    }

    load()
  }, [order, handleLocation])

  return (
    <>
      <SEO title="Order Completed" canonicalUrl="/checkout" breadcrumbsList={[
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://teklafabrics.com",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Checkout",
          item: "https://teklafabrics.com/checkout",
        },
        {
          "@type": "ListItem",
          position: 3,
          name: "Order Completed",
          item: "https://teklafabrics.com/checkout/done",
        },
      ]} />
      {!(order?.id || order?.tooOld) || fetchingOrder ? (
        <LoadingSpinner />
      ) : (
        order && (
          <>
            <OrderDone order={order} location={mapLocation} splitOrderCart={splitOrderCart} />
            {order.payments[0] && order.payments[0].provider_id === "klarna" && (
              <div
                id="klarna-container"
                dangerouslySetInnerHTML={{
                  __html: order.payments[0].data.html_snippet,
                }}
              />
            )}
          </>
        )
      )}
    </>
  )
}

export default CheckoutDone
